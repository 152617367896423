<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >


    <div>

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <h1>Cashback Setting</h1>
        <validation-observer ref="updateCashbackSetting" tag="div">
          <b-row>
            <b-col cols="12" md="3">
              <b-form-group
                  label="Cashback Percentage %"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  {{ cashbackPercentage }}
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="cashbackPercentage"
                      :state="errors.length > 0 ? false:null"

                      max="50"
                      min="1"
                      type="range"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="d-flex align-items-center mt-1" cols="12" md="3">
              <b-button
                  class=" "
                  variant="primary"
                  @click="updateCashbackSetting"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import { BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow, } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Helper from '@/libs/Helper'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { GetCashbackSetting, UpdateCashbackSetting } from '@/libs/Api/SiteSettings'

export default {
  title: 'general-setting',
  name: 'general-setting',
  data() {
    return {
      required,
      cashbackPercentage: null,
      showOverlay: false
    }
  },
  async created() {
    await Promise.all([
      this.getCashbackSetting()
    ])
  },

  methods: {
    setSelectedSetting(item) {
      this.selectedSetting = JSON.parse(JSON.stringify(item))
    },
    async getCashbackSetting() {
      let _this = this
      _this.showOverlay = true
      let getCashbackSetting = new GetCashbackSetting(_this)

      await getCashbackSetting.fetch(function (content) {
        _this.cashbackPercentage = content.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async updateCashbackSetting() {
      let _this = this
      Helper.validateForm(_this, 'updateCashbackSetting', async () => {
        _this.showOverlay = true
        let updateCashbackSetting = new UpdateCashbackSetting(_this)
        updateCashbackSetting.setParams({
          value: _this.cashbackPercentage
        })
        await updateCashbackSetting.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `setting successfully updated`,
            },
          })
          _this.getCashbackSetting()
          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })

    },

  },

  components: {
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    vSelect,
  },
}
</script>

<style scoped>

</style>
