import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllSettings extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('StripeSettings')
  }
}

class GetCashbackSetting extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('SiteSettings/GetCashBackDefaultPercantage')
  }
}

class UpdateCashbackSetting extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('SiteSettings/UpdateBackDefaultPercantage')
  }

  setParams(data){
    super.setRequestParam(data)
  }
}

class CreateSetting extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('StripeSettings')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class UpdateSetting extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('StripeSettings')
  }

  setParams(data) {
    super.setRequestParam(data)
  }

  setPayload(data) {
    super.setRequestParamDataObj(data)
  }
}

class DeleteSetting extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
  }

  setParams(data) {
    super.setTag(`StripeSettings/${data.id}`)

  }
}

export {
  UpdateSetting,
  GetAllSettings,
  GetCashbackSetting,
  UpdateCashbackSetting

}
